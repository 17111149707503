:root {
  --swiper-navigation-size: 44px;
}

.swiper-button-prev, .swiper-button-next {
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: .35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev.swiper-button-hidden, .swiper-button-next.swiper-button-hidden {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

.swiper-navigation-disabled .swiper-button-prev, .swiper-navigation-disabled .swiper-button-next {
  display: none !important;
}

.swiper-button-prev svg, .swiper-button-next svg {
  object-fit: contain;
  transform-origin: center;
  width: 100%;
  height: 100%;
}

.swiper-rtl .swiper-button-prev svg, .swiper-rtl .swiper-button-next svg {
  transform: rotate(180deg);
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 10px);
  right: auto;
}

.swiper-button-lock {
  display: none;
}

.swiper-button-prev:after, .swiper-button-next:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  text-transform: none !important;
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: "prev";
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 10px);
  left: auto;
}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: "next";
}
/*# sourceMappingURL=index.81958038.css.map */
